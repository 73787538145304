<template>
<div class="content">
    <h1>{{hello}}</h1>
    <router-link to="/mood" class="w3-button w3-padding-large w3-text-white houseColorBackground">+</router-link>
</div>
</template>
  
<script>
export default {
name: "Start",
  data() {
    return {
        hello: "Willkommen :)"
    }
  }
}
</script>

<style>
.content{
  padding: 10px;
}
</style>